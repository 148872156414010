import { gql } from '@/shared/graphql/generated/explorer';

export const getExtrinsicByBlockIdAndIndex = gql(/* GraphQL */ `
  query getExtrinsicByBlockIdAndIndex($blockId: Int!, $index: Int!) {
    extrinsics: allExtrinsics(condition: { blockId: $blockId, indexInBlock: $index }, first: 1) {
      nodes {
        ...Extrinsic
        events: eventsByExtrinsicId {
          nodes {
            indexInBlock
            metadata: metadatumByMetadataId {
              name
            }
          }
        }
      }
    }
  }
`);

export const getExtrinsicCount = gql(/* GraphQL */ `
  query getExtrinsicCount {
    allExtrinsics(last: 1) {
      nodes {
        id
      }
    }
  }
`);
